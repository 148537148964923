<script setup lang="ts">
// types
import type { Nullable } from '@revolutionprep/types'

// vuetify
import { useTheme } from 'vuetify'

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * nuxt runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * route
 * ==================================================================
 */
const route = useRoute()

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const renderError = ref(false)

/**
 * stores
 * ==================================================================
 */
const organizationStore = useOrganizationStore()
const { organizationInfo } = storeToRefs(organizationStore)

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

const isSpoofing = computed(() => {
  return Boolean($actor.core.spoofing.value)
})

// styles
const containerClasses = computed(() => {
  return isLoggedIn.value
    ? 'container mx-auto pt-12'
    : 'container pa-0 d-flex align-center justify-center'
})

const mainStyles = computed(() => {
  return {
    background: `${vuetifyTheme.current.value.colors.backgroundgrey}`,
    height: `calc(100% - ${isSpoofing.value ? '128px' : '70px'})`
  }
})

// tutor matching steps
const steps = computed(() => {
  return [
    {
      routePath: `/tutor-matching/${tutorMatchId.value}/`,
      stepNumber: 1,
      stepTitle: 'Program Details'
    },
    {
      routePath: `/tutor-matching/${tutorMatchId.value}/availability`,
      stepNumber: 2,
      stepTitle: 'Availability'
    },
    {
      routePath: `/tutor-matching/${tutorMatchId.value}/submit`,
      stepNumber: 3,
      stepTitle: 'Submit'
    }
  ]
})

const tutorMatchId = computed(() => {
  return Number(route.params.matchId)
})

const tutorMatchingHeading = computed<string | undefined>(() => {
  return $actor.core.storage.getState('tutorMatchingHeading')
})

const tutorMatchingStep = computed<number | undefined>(() => {
  return $actor.core.storage.getState('tutorMatchingStep')
})

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <ToolbarSpoof />
    <v-main
      :style="mainStyles"
      class="mb-4"
    >
      <v-container
        :class="containerClasses"
        :fill-height="!isLoggedIn"
      >
        <div v-if="!renderError">
          <header class="d-flex">
            <client-only>
              <h1
                v-if="tutorMatchingHeading"
                class="text-h5 font-weight-bold mb-4"
              >
                {{ tutorMatchingHeading }}
              </h1>
              <template #fallback>
                <v-skeleton-loader
                  type="heading"
                  width="50%"
                />
              </template>
            </client-only>
          </header>
          <client-only>
            <LazyStepper
              :steps="steps"
              :value="tutorMatchingStep"
            />
            <template #fallback>
              <v-skeleton-loader
                type="heading"
                width="100%"
              />
            </template>
          </client-only>
        </div>
        <slot v-if="!renderError" />
        <r-error-display
          v-else-if="renderError && capturedError"
          :error="capturedError"
        />
      </v-container>
    </v-main>
    <client-only>
      <r-footer
        :app="isLoggedIn"
        :company="organizationInfo"
        :logged-in="isLoggedIn"
        :settings="config"
      />
    </client-only>
  </v-app>
</template>

<style scoped>
.container {
  height: 100%;
}

@media (min-width: 600px) and (max-width: 960px) {
  .container {
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}
</style>
